import React from "react";

import {
    DefaultLayout,
    Layout,
    withPrivateRoute,
    AdminVersiebeheer as AdminVersiebeheerContainer,
} from "@containers";

/**
 * AdminVersiebeheer
 */

const AdminVersiebeheer = props => (
    <Layout {...props}>
        <DefaultLayout title="Versiebeheer">
            <AdminVersiebeheerContainer id="Versiebeheer" />
        </DefaultLayout>
    </Layout>
);

export default withPrivateRoute(AdminVersiebeheer);
